<mat-card>
  <mat-card-header>
    <mat-card-title>Enter access code to see live location update</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form>
      <mat-form-field>
        <input matInput placeholder="Access Code" [(ngModel)]="accessCode" type="password" name="accessCode" required />
      </mat-form-field>
      <mat-card-actions>
        <button mat-raised-button (click)="login()" color="primary">Check location</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>