import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private appTitle = 'Bikeaholic';

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) { }

  init() {
    this.router.events
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route) => route.snapshot),
      map((snapshot) => {
        if (snapshot && snapshot.data && snapshot.data.title && snapshot.data.title.length > 0)
          return snapshot.data.title;
        return null;
      }))
      .subscribe(title => this.setTitle(title));
  }

  setTitle(title: string) {
    if (title && title.length > 0)
      title = title + ' | ' + this.appTitle;
    else
      title = this.appTitle;
    this.titleService.setTitle(title);
  }
}
