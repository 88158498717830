<mat-toolbar color="primary" class="mat-elevation-z6">
  <mat-toolbar-row>
    <a mat-button color="accent" routerLink="/">
      <h2>Bikeaholic</h2>
    </a>
    <!-- <a mat-button color="accent" routerLink="/blog">
      Blog
    </a> -->
    <span class="toolbar-spacer"></span>
  </mat-toolbar-row>
</mat-toolbar>
