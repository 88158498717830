import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageComponent } from '../message/message.component';
import { MarkerService } from '../../services/marker.service';

@Component({
  selector: 'app-access-code',
  templateUrl: './access-code.component.html',
  styleUrls: ['./access-code.component.scss']
})
export class AccessCodeComponent implements OnInit {

  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<AccessCodeComponent>, private markerService: MarkerService) {
  }
  public id: string = ""
  public accessCode: string = "";

  ngOnInit() {
  }

  login() {
    this.dialogRef.close();
    this.markerService.checkAccessCode(this.accessCode);
  }
}
