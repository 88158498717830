<script src="js/jquery-1.12.4.min.js"></script>
<script src="js/owl.carousel.min.js"></script>
 <script src="js/wow.min.js"></script>
<script src="js/main.js"></script>
<script src="js/isotope.pkgd.min.js"></script>
<!-- slider_area_start -->
<div class="slider_area">
    <div class="single_slider  d-flex align-items-center slider_bg_1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-7 col-md-6">
                    <div class="slider_text ">
                        <h3 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s" >Bikeaholic<br>
                            Cycling companion</h3>
                        <p class="wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">Get more from the app what you use most</p>
                        <div class="video_service_btn wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">
                            <a href="https://bit.ly/bikeaholicapp" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-04-29&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-md-6">
                    <div class="phone_thumb wow fadeInDown" data-wow-duration="1.1s" data-wow-delay=".2s">
                        <img src="assets/phone.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- slider_area_end -->
<!-- service_area  -->
<div class="service_area">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <div class="section_title text-center  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s">
                    <h3>Save your workouts <br>
                            and track your progress</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="single_service text-center wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".4s">
                    <div class="thumb">
                            <img src="assets/2.svg" alt="">
                    </div>
                    <h3>View detailed<br>
                        analytics</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-4">
                    <div class="single_service text-center wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
                            <div class="thumb">
                                    <img src="assets/3.svg" alt="">
                            </div>
                            <h3>Save your bikes <br> 
                                    and track service history</h3>
                        </div>
            </div>
            <div class="col-lg-4 col-md-4">

                        <div class="single_service text-center wow fadeInUp " data-wow-duration=".8s" data-wow-delay=".6s">
                            <div class="thumb">
                                    <img src="assets/1.svg" alt="">
                            </div>
                            <h3>Create and reach
                                    your own goals</h3>
                        </div>
            </div>
        </div>
    </div>
</div>
<!--/ service_area  -->

<!-- service_area_2  -->
<div class="service_area_2">
    <div class="container">
        <div class="row align-items-center">
            <div class="fadeInLeft" data-wow-duration=".4s" data-wow-delay=".5s">
                <div class="man_thumb">
                    <img src="assets/bike.png" alt="">
                </div>
            </div>
           
        </div>
        <div class="service_content_wrap">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="single_service  wow fadeInUp" data-wow-duration=".5s" data-wow-delay=".3s">
                        <span>01</span>
                        <h3>Download the app for free</h3>
                        <p>Open the store on your iPhone, press download.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="single_service  wow fadeInUp" data-wow-duration=".6s" data-wow-delay=".4s">
                        <span>02</span>
                        <h3>Go out for a ride</h3>
                        <p>Track your workout in the app</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4  wow fadeInUp" data-wow-duration=".7s" data-wow-delay=".5s">
                    <div class="single_service">
                        <span>03</span>
                        <h3>Enjoy</h3>
                        <p>You can do much more. Save your bikes, beat your goals, share your location.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="productivity_area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-7 col-md-12 col-lg-6">
                <h3 class="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">Get start from now <br>
                        and enjoy the benefits forever</h3>
            </div>
            <div class="col-xl-5 col-md-12 col-lg-6">
                <div class="app_download wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
                    <a href="https://bit.ly/bikeaholicapp" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/en-gb/badge-lrg.svg?releaseDate=2019-04-29&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;"></a>
                </div>
            </div>
        </div>
    </div>
</div>
