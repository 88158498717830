
  <div>
    &copy; Bikeaholic 2020
    <a mat-button color="accent" routerLink="/conditions">
      Terms & Conditions
    </a>
    <a mat-button color="accent" routerLink="/privacyPolicy">
      Privacy Policy
    </a>
    <a mat-button color="accent" href="https://bikeaholic.app/assets/MediaKit.zip">
      Media Kit
    </a>
  </div>
