import { AfterViewInit, Component } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import * as L from 'leaflet';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AccessCodeComponent } from '../access-code/access-code.component';
import { MarkerService } from '../../services/marker.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  private map;
  public id: string;

  constructor(private markerService: MarkerService, private dialog: MatDialog, private router: Router) {
    this.getRouteParams();
  }

  getRouteParams() {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
    ).subscribe(object => {
      if (this.markerService.userName == undefined) {
        this.markerService.userName = (object as ActivationEnd).snapshot.params['userName'];
        this.id = this.markerService.userName;
      }
    }
    );
  }

  ngOnInit() {
    this.dialog.open(AccessCodeComponent);
  }

  // Store parameter values on URL changes

  ngAfterViewInit(): void {
    this.initMap()
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [39.8282, -98.5795],
      zoom: 3
    });
    this.markerService.map = this.map;

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });
    tiles.addTo(this.map);
  }
}
