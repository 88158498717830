import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from '../../shared/services/title.service';

@Component({
  selector: 'app-blog-entry',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.scss']
})
export class BlogEntryComponent implements OnInit, OnDestroy {
  private sub: any;
  blogEntryId: number;
  constructor(private route: ActivatedRoute, private titleService: TitleService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.blogEntryId = +params['blogId'];
      this.titleService.setTitle( this.blogEntryId + ' - Szerkesztes');
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
