import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from './angular-material.module';
import { AccessCodeComponent } from './components/access-code/access-code.component';
import { MapComponent } from './components/map/map.component';
import { MessageComponent } from './components/message/message.component';

@NgModule({
  imports: [CommonModule, AngularMaterialModule, FormsModule],
  declarations: [MessageComponent, AccessCodeComponent, MapComponent],
  exports: [AngularMaterialModule, FormsModule, AccessCodeComponent, MapComponent, MessageComponent],
  entryComponents: [MessageComponent],
  providers: []
})
export class SharedModule {
  constructor() {
  }
}
