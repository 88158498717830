import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, interval } from 'rxjs';
import { switchMap, startWith } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { MessageComponent } from '../components/message/message.component';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})



export class MarkerService {

  public userName: string;
  marker: L.marker
  public accessCode: string = '';
  public map;
  subscription: Subscription;
  dialogRef: MatDialogRef<MessageComponent>;

 
  constructor(private http: HttpClient, private config: ConfigService, private dialog: MatDialog) {

  }

  checkAccessCode(accessCode: string) {
   
    this.http.post(this.config.userLink + "users/checkAccessCode", {"accessCode": accessCode, "userName":this.userName}).subscribe( (result: any) => {
      if (result.isValid == true) {
        this.fetchLocation()
      } else {
        this.dialog.open(MessageComponent, {
          data: {
            message: "Invalid access code! Please check your input."
          }
        });
      }
    })
  }

  fetchLocation() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }

    this.subscription = interval(60000).pipe(
      startWith(0),
      switchMap(() => this.fetchAPI())
    ).subscribe((result: any) => {
      if (this.marker != undefined) {
        this.map.removeLayer(this.marker)
      }
      if (this.dialogRef != undefined) {
        this.dialogRef.close()
      }
      if (result.timeStamp) {
        this.map.setView({ lon: result.location.longitude, lat: result.location.latitude }, 16);
        // create custom icon
        var firefoxIcon = L.icon({
          iconUrl: '/assets/marker.png',
          iconSize: [35, 35], // size of the icon
        });
        this.marker = L.marker([result.location.latitude, result.location.longitude], { icon: firefoxIcon })
        this.map.addLayer(this.marker);
      } else {
        this.dialogRef = this.dialog.open(MessageComponent, {
          data: {
            message: "Location not found for " + this.userName
          }
        });
      }
    });
  }

  fetchAPI() {
    return this.http.get(this.config.userLink + "track/" + this.userName)
  }
}
